import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import VideoGallery from "../components/global/VideoGallery";


function OurVideos() {
    const { rpdata } = useContext(GlobalDataContext);


    return (
        <BaseLayout PageName="Our Videos">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle="Our Videos"
                    Subheader="Our Videos"
                    bgimg={`${rpdata?.stock?.[0]}`}
                />
            </div>
            <div className="w-4/5 mx-auto py-[100px]">
                <h1 className="text-center pb-10">Our Videos</h1>
                <VideoGallery />
            </div>
        </BaseLayout>
    );
}

export default OurVideos;